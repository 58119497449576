import { render, staticRenderFns } from "./Santa-Cruz-do-Sul.vue?vue&type=template&id=0d98fc51&scoped=true&"
import script from "./Santa-Cruz-do-Sul.vue?vue&type=script&lang=js&"
export * from "./Santa-Cruz-do-Sul.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d98fc51",
  null
  
)

export default component.exports